(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/document-title/assets/javascripts/document-title.js') >= 0) return;  svs.modules.push('/components/lb-utils/document-title/assets/javascripts/document-title.js');

'use strict';

function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
class DocumentTitle extends React.Component {
  constructor() {
    super(...arguments);
    _defineProperty(this, "confirmUpdate", () => {
      const {
        title
      } = this.props;
      if (title && title !== document.title) {
        this.oldTitle = document.title;
        document.title = title;
      }
    });
  }
  componentDidMount() {
    this.confirmUpdate();
  }
  componentDidUpdate() {
    this.confirmUpdate();
  }
  componentWillUnmount() {
    if (this.oldTitle && document.title === this.props.title) {
      document.title = this.oldTitle;
    }
  }
  render() {
    return null;
  }
}
setGlobal('svs.components.lbUtils.DocumentTitle', DocumentTitle);

 })(window);